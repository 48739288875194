<template>
    <div class="p-page" :class="{ 'remove-padding-bottom': page.removePaddingBottom }">
        <app-section class="l-sections">
            <app-builder
                v-if="page.sections && page.sections.length"
                :sections="page.sections"
                :display-anchors="page.hasAnchors"
            >
                <template #beforeSections v-if="page && page.parent && page.hasAnchors">
                    <h2 class="p-page__title | t-t3">{{ page.title }}</h2>
                </template>
            </app-builder>
        </app-section>
    </div>
</template>

<script>
import AppBuilder from "layout/AppBuilder";
import AppSection from "layout/AppSection";

import { SECTION_HANDLES } from "src/constants";

export default {
    name: "Page",
    components: {
        AppBuilder,
        AppSection
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        };
    },
    props: {
        page: Object
    },
    data: () => ({
        SECTION_HANDLES
    }),
    watch: {
        page: {
            handler() {
                if (
                    this.page &&
                    this.page.sections &&
                    this.page.sections.length === 0 &&
                    this.page.children &&
                    this.page.children.length > 0
                ) {
                    this.$router.push(`/${this.page.children[0].uri}`);
                }
            },
            deep: true
        }
    }
};
</script>

<style lang="scss">
.p-page {
    .p-page__title {
        margin-bottom: var(--grid-gutter);
    }

    &.remove-padding-bottom {
        --section-padding-bottom: 0;
    }
}
</style>
