<template>
    <header
        ref="header"
        class="l-header | container"
        :class="{ '-has-background': model == 'background' }"
        :style="styles"
    >
        <div class="l-header__inner | grid">
            <!-- TOP -->
            <div class="l-header__top">
                <component :is="logoTag">
                    <app-logo ref="logo" :isMonochrome="page.section != 'home'" className="l-header__logo" />
                </component>

                <div v-if="page.section == 'home' && buttons" class="l-header__actions | c-btn__group">
                    <btn
                        v-for="(button, i) in buttons"
                        :key="`home-header-btn-${i}`"
                        :tag="button.url == '#infolettre' ? 'router-link' : button.tag"
                        :url="button.url"
                        :label="button.label"
                        :bg-color="button.colour"
                        :is-shaped="button.isShape"
                        :iconAfter="button.iconAfter"
                    />
                </div>
            </div>

            <!-- HOMEPAGE SPECIFICS -->
            <template v-if="page.section == 'home'">
                <div class="l-header__content -home">
                    <div class="l-header__text | t-content">
                        <highlight-text tag="h2" :text="page.heading" class="t-t1" />
                        <div class="t-cms -lead">
                            <p v-html="page.description" />
                        </div>
                        <div v-if="page.buttons" class="-actions | c-btn__group">
                            <btn
                                v-for="(button, i) in page.buttons"
                                :key="`home-content-btn-${i}`"
                                :tag="button.tag"
                                :url="button.url"
                                :label="button.label"
                                :bg-color="button.colour"
                                :is-shaped="button.isShape"
                                :iconAfter="button.iconAfter"
                            />
                        </div>
                    </div>

                    <div class="l-header__medias">
                        <header-medias
                            :asset="page.hero || page.image"
                            :assetShape="page.assetShape"
                            :shape="page.shape"
                            @imageLoaded="imageLoaded()"
                        />
                    </div>
                </div>
            </template>

            <!-- R.O.T. -->
            <template v-else>
                <div class="l-header__content">
                    <div
                        class="l-header__text | t-content"
                        :class="{
                            '-pt': headerTextHeight <= headerMediasHeight,
                            '-has-back-control': parentSection
                        }"
                    >
                        <!-- BACK BUTTONS -->
                        <template v-if="parentSection">
                            <btn
                                tag="router-link"
                                :url="SECTION_HANDLES[parentSection.parent] | resolver"
                                :label="parentSection.label"
                                bg-color="light"
                                bg-hover-color="beige"
                                :is-shaped="true"
                                shape-dir="left"
                            />
                        </template>

                        <!-- CAMPAIN CUSTOM HEADER -->
                        <div v-if="page.section === 'campaign'" class="--variant-campaign | t-content">
                            <tag :label="page.edition" text-color="dark" bg-color="beige" />
                            <span v-if="page.beginDate && page.endDate" class="-dates | t-small">
                                {{ formatDateSpan(page.beginDate, page.endDate) }}
                            </span>
                            <h1 class="t-t3">
                                <template v-if="page.logo">
                                    <img :src="page.logo.url" :alt="page.title" class="-logo" />
                                    <span class="visibility-hidden">{{ page.title }}</span>
                                </template>
                                <template v-else>
                                    {{ page.title }}
                                </template>
                            </h1>
                        </div>

                        <!-- ELSE -->
                        <template v-else>
                            <h1 class="t-t3 | -title">
                                {{ page.heading || (page.parent ? page.parent.title : page.title) }}
                            </h1>
                            <div class="t-cms -lead -description">
                                <p v-html="page.parent ? page.parent.description : page.description" />
                            </div>
                        </template>
                    </div>

                    <div v-if="model == 'background'" class="l-header__background">
                        <asset :asset="asset" />
                    </div>

                    <div v-else class="l-header__medias">
                        <header-medias
                            :model="model"
                            :asset="page.hero || asset"
                            :assetShape="page.parent ? page.parent.assetShape : page.assetShape"
                            :shape="page.parent ? page.parent.shape : page.shape"
                            @imageLoaded="imageLoaded()"
                        />
                    </div>
                </div>
            </template>

            <!-- NAV -->
            <div class="l-header__nav" :class="navPosition ? '-top' : '-bottom'">
                <app-nav ref="nav" @toggle="toggleNav" />
            </div>
        </div>
    </header>
</template>

<script>
import AppLogo from "objects/AppLogo";
import Tag from "objects/Tag";

import Asset from "objects/Asset";
import Btn from "components/Btn";
import HeaderMedias from "components/HeaderMedias";
import HighlightText from "components/HighlightText";

import AppNav from "layout/AppNav";

import { SECTION_HANDLES } from "src/constants";
import Variables from "src/mixins/variables";
import { formatDateSpan } from "src/utils";
import { mapState } from "vuex";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
    name: "AppHeader",
    components: {
        AppLogo,
        Asset,
        AppNav,
        Btn,
        HeaderMedias,
        HighlightText,
        Tag
    },
    mixins: [Variables],
    data: () => ({
        SECTION_HANDLES,
        navPosition: true,
        navTrigger: null,
        headerHeight: 0,
        headerTextHeight: 0,
        headerMediasHeight: 0,
        headerMediasWidth: 0,
        headerTrigger: null,
        delay: 100,
        progress: 0,
        parentRelations: {
            project: {
                parent: "PROJECTS",
                label: "Retour aux projets"
            },
            campaign: {
                parent: "CAMPAIGNS",
                label: "Retour aux campagnes"
            },
            issue: {
                parent: "ISSUES",
                label: "Retour aux enjeux"
            },
            post: {
                parent: "POSTS",
                label: "Retour aux publications"
            },
            pressArticle: {
                parent: "PRESS",
                label: "Retour à l’espace presse"
            },
            vision: {
                parent: "VISIONS",
                label: "Retour aux visions"
            }
        }
    }),
    created() {
        // Add Window events
        window.addEventListener("resizeEnd", this.init);
    },
    computed: {
        ...mapState({
            page: state => state.global.currentPage,
            buttons: state => state.global.settings.buttons,
            loading: state => state.loader.loading
        }),
        parentSection() {
            if (this.page && this.page.section) return this.parentRelations[this.page.section];
        },
        currentSection() {
            return this.page.section || null;
        },
        logoTag() {
            return this.page.section == "home" ? "h1" : "div";
        },
        asset() {
            if (this.page.parent) {
                if (this.page.parent.useBackgroundHeaderModel) return this.page.parent.backgroundImage;

                return this.page.parent.image[0];
            }

            if (this.page.useBackgroundHeaderModel) return this.page.backgroundImage;

            return this.page.image;
        },
        model() {
            if (this.page.parent) {
                if (this.page.parent.useBackgroundHeaderModel) return "background";

                return this.page.parent.headerModel;
            }

            if (this.page.useBackgroundHeaderModel) return "background";

            return this.page.headerModel;
        },
        styles() {
            let styles = "";

            styles += `
                --perc: ${this.progress};
                --header-top-height: ${this.headerTopHeight}px;
                --header-height: ${this.headerHeight}px;
                --header-text-height: ${this.headerTextHeight}px;
                --header-medias-height: ${this.headerMediasHeight}px;
                --header-medias-width: ${this.headerMediasWidth > 450 ? 450 : this.headerMediasWidth}px;
            `;

            return styles;
        }
    },
    methods: {
        toggleNav(value) {
            this.$emit("toggleNav", value);
        },
        imageLoaded() {
            // Ready mechanism here?
            this.init();
        },
        init() {
            this.headerTopHeight = document.querySelector(".l-header__top").offsetHeight;
            this.headerHeight = document.querySelector(".l-header").offsetHeight;

            if (this.$el.querySelector(".l-header__medias")) {
                this.headerTextHeight = this.$el.querySelector(".l-header__text").offsetHeight;
                this.headerMediasHeight = this.$el.querySelector(".l-header__medias").offsetHeight;

                this.headerMediasWidth = this.$el.querySelector(".l-header__medias").offsetWidth;
            }

            setTimeout(() => {
                const gap = this.W.smallScreen ? "10px" : "20px"; // See base/_variables.scss, $grid-gutters

                if (this.navTrigger) {
                    this.navTrigger.kill();
                    document.body.style.removeProperty("overflow");
                }

                if (this.headerTrigger) this.headerTrigger.kill();

                if (!this.W.smallScreen) {
                    this.navTrigger = ScrollTrigger.create({
                        trigger: ".l-header__nav",
                        pin: true,
                        pinSpacing: false,
                        start: `top top+=${gap}`,
                        endTrigger: "#footer",
                        end: "bottom top",
                        onToggle: self => {
                            const el = document.querySelector(".l-wrapper");
                            if (self.isActive) el.classList.add("header-is-hidden");
                            else el.classList.remove("header-is-hidden");
                        }
                    });
                } else {
                    this.navTrigger = ScrollTrigger.create({
                        trigger: ".l-header",
                        start: "top top",
                        end: "bottom top+=60px",
                        onToggle: self => {
                            const el = document.querySelector(".l-wrapper");
                            if (!self.isActive) el.classList.add("header-is-hidden");
                            else el.classList.remove("header-is-hidden");
                        }
                    });
                }

                this.headerTrigger = ScrollTrigger.create({
                    trigger: ".l-header",
                    start: "top top",
                    end: "bottom top+=60px",
                    onUpdate: self => {
                        this.progress = self.progress.toFixed(3);
                    }
                });
            }, this.delay); // Let time to re-render
        },
        formatDateSpan(b, e) {
            return formatDateSpan(b, e);
        }
    },
    watch: {
        currentSection(section) {
            if (section !== undefined && section) {
                this.navPosition = section == "home";
                this.init();
            }
        },
        loading(value) {
            if (value == 0) {
                ScrollTrigger.refresh();
            }
        }
    },
    destroyed() {
        // Remove Window events
        window.removeEventListener("resizeEnd", this.init);
    }
};
</script>

<style lang="scss">
.l-header {
    .l-header__top {
        padding: var(--grid-gutter) 0 0;
        z-index: 2;

        @media #{md("lg")} {
            padding: calc(var(--container-gutter) - var(--grid-gutter-half)) 0 0;
        }

        .o-logo {
            svg {
                --svg-width: 11.5rem;

                @media #{md("xs")} {
                    --svg-width: 14rem;
                }
                @media #{md("sm")} {
                    --svg-width: 20rem;
                }
            }
        }

        .p-home & {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-right: var(--safe-nav-padding);

            .l-header__actions {
                margin-top: calc(-1 * var(--grid-gutter-half));

                @media #{md('sm', 'max')} {
                    display: none;
                }
                @media #{md('lg')} {
                    margin-top: calc(-1 * var(--grid-gutter)) !important;
                }
            }
        }
    }

    .l-header__inner {
        @media #{md("sm", "max")} {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .pin-spacer {
        z-index: 2 !important;
    }

    .l-header__nav {
        position: fixed;
        top: calc(var(--grid-gutter-half) - 0.5px);
        right: var(--grid-gutter-half);
        z-index: 10;

        @media #{md("sm")} {
            z-index: 1;

            &.-top {
                position: absolute;
                top: -0.5px;
                right: calc((var(--container-gutter) - var(--grid-gutter)) * -1 - 0.5px);
            }

            &.-bottom {
                position: absolute;
                top: auto;
                bottom: calc(var(--nav-trigger-height) * -1 - var(--tabs-height, 0));
                right: calc((var(--container-gutter) - var(--grid-gutter)) * -1 - 0.5px);
            }
        }
        @media #{md("md")} {
            &.-top {
                right: calc((var(--container-gutter) - var(--grid-gutter-half)) * -1 - 0.5px);
            }
            &.-bottom {
                right: calc((var(--container-gutter) - var(--grid-gutter-half)) * -1 - 0.5px);
            }
        }
    }

    .l-header__content {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: var(--grid-gutter);

        &.-home {
            margin-top: var(--container-gutter);
            padding-bottom: var(--container-gutter);
            overflow: visible;

            @media #{md("xs")} {
                margin-top: calc(var(--container-gutter) + var(--grid-gutter));
            }

            @media #{md("sm")} {
                margin-top: var(--container-gutter);
                padding-right: calc(var(--nav-trigger-width) / 2);
            }

            .l-header__text {
                --content-gap: 4rem;
                padding-top: var(--grid-gutter);
                grid-column: 1 / 13;
                grid-row: 2 / 2;

                @media #{md("sm")} {
                    grid-column: 1 / 13;
                }

                @media #{md("md")} {
                    grid-column: 1 / 8;
                    grid-row: 1 / 1;
                }

                @media #{md("lg")} {
                    grid-column: 1 / 8;
                }

                .t-t1 {
                    @include responsive-type($min-font: 35, $max-font: 60, $min-width: 480, $max-width: 1440);
                }

                .t-cms {
                    max-width: 45rem;
                }
            }

            .l-header__medias {
                grid-column: 2 / 12;
                grid-row: 1 / 1;

                @media #{md("xs")} {
                    grid-column: 1 / 9;
                }

                @media #{md("md")} {
                    grid-column: 8 / 13;
                    grid-row: 1 / 1;
                }

                @media #{md("lg")} {
                    grid-column: 8 / 13;
                }

                .c-decos {
                    .c-decos__asset {
                        height: auto;
                    }
                    @media #{md("xs", "max")} {
                        .o-shape {
                            left: 0;
                            top: calc(100% * -1 - var(--container-gutter) - var(--grid-gutter));
                            transform: scale(0.6);
                            transform-origin: bottom;
                        }
                    }
                    @media #{md("md")} {
                        max-width: 45rem;
                        margin-left: auto;
                    }
                }
            }
        }

        &:not(.-home) {
            @media #{md("sm")} {
                padding-right: var(--safe-nav-padding);
            }

            .l-header__text {
                --content-gap: 3rem;

                grid-column: 1 / 10;
                padding-top: calc(2 * var(--grid-gutter));
                padding-bottom: var(--grid-gutter);
                z-index: 2;

                @media #{md("sm")} {
                    padding-top: var(--grid-gutter);
                }

                @media #{md("md")} {
                    grid-column: 1 / 7;
                    grid-row: 1 / 1;
                    //outline: 1px solid red;
                }
                @media #{md("lg")} {
                    grid-column: 1 / 7;
                    padding-top: var(--container-gutter);

                    &.-has-back-control {
                        padding-top: var(--grid-gutter);
                    }
                    //outline: 1px solid blue;
                }
                @media #{md("xl")} {
                    grid-column: 1 / 6;
                    //outline: 1px solid orange;
                }
                .-title {
                    max-width: 16ch;
                }
                .-description {
                    a {
                        text-decoration: none;
                        @include link($underline: true);

                        &:hover {
                            color: $color-dark;
                        }
                    }
                }

                .--variant-campaign {
                    --content-gap: 1rem;

                    .-logo {
                        height: 4.5rem;

                        @media #{md("sm")} {
                            height: 6rem;
                        }
                    }
                }
            }

            .l-header__medias {
                --perc-25: clamp(0, calc(var(--perc) - 0.25), 1);
                grid-column: 2 / 12;
                align-self: end;
                //outline: 1px solid purple;

                opacity: calc(var(--perc-25) * -1.65 + 1);
                will-change: opacity;

                @media #{md("sm")} {
                    opacity: calc(var(--perc-25) * -1.35 + 1);
                    grid-column: 1 / 10;
                    //outline: 1px solid pink;
                }

                @media #{md("md")} {
                    grid-column: 7 / 13;
                    grid-row: 1 / 1;
                    //outline: 1px solid red;
                }
                @media #{md("lg")} {
                    grid-column: 7 / 13;
                    //outline: 1px solid blue;
                }

                .c-decos,
                .o-shape svg {
                    @media #{md("md")} {
                        max-width: 45rem;
                        margin-left: auto;
                    }
                }

                // .c-decos:not(.-background) {
                .o-asset {
                    transform: translateY(calc(var(--perc-25) * 100% / 20)) rotate(calc(var(--perc-25) * 100deg / 7));
                    transform-origin: 10% 60%;
                    will-change: transform;

                    html.is-firefox & {
                        transform: translateY(calc(var(--perc-25) * 100% / 20))
                            rotate(calc(var(--perc-25) * 100deg / 20));
                    }

                    img {
                        transform-origin: 10% 60%;
                        transform: scale(1.1) rotate(calc(var(--perc-25) * 100deg / -8));
                        will-change: transform;

                        html.is-firefox & {
                            transform: none;
                        }
                    }
                }
                // }
            }
        }
    }

    .l-header__background {
        display: none;

        @media #{md("md")} {
            --offset-top: calc(var(--header-top-height) + var(--grid-gutter-half));
            --offset-right: calc(var(--safe-nav-padding) - var(--grid-gutter-half));
            display: block;
            grid-column: 5 / 14;
            grid-row: 1 / 1;
            position: absolute;
            top: calc(var(--offset-top) * -1);
            height: calc(var(--header-height) + var(--grid-gutter));
            right: calc(var(--offset-right) * -1);

            .o-asset,
            img {
                height: 100%;
                object-fit: contain;
                object-position: top right;
            }
        }
    }
}
</style>
