<template>
    <div class="b-content-carousel">
        <slider
            v-if="slides && slides.length >= 3"
            :slides="slides"
            ref="slider"
            :fullheight="false"
            :prevNext="true"
            :show-index="true"
            :zoom="false"
            modifier="gallery"
            class="b-content-carousel__slider"
            :options="{
                wrapAround: false,
                draggable: true,
                groupCells: true,
                cellAlign: 'left'
            }"
        >
            <template #slide="slide">
                <article class="b-content-carousel__slide">
                    <asset :asset="slide.image" />
                    <div class="b-content-carousel__slide-content | t-content">
                        <h1 class="t-t5">{{ slide.heading }}</h1>
                        <p>{{ slide.description }}</p>
                    </div>
                </article>
            </template>
        </slider>
    </div>
</template>

<script>
import Asset from "objects/Asset";
import Slider from "components/Slider";

export default {
    name: "BlockContentCarousel",
    components: {
        Asset,
        Slider
    },
    props: {
        data: false
    },
    computed: {
        slides() {
            if (this.data && this.data.slides) {
                let slides = this.data.slides; //.map(imgObj => imgObj.image[0])

                return slides;
            }

            return [];
        }
    }
};
</script>

<style lang="scss">
.b-content-carousel {
    display: flex;
    flex-direction: column-reverse;

    &__slide {
        display: flex;
        flex-direction: column;
        gap: var(--grid-gutter);

        &-content {
            h1 {
                max-width: 15ch;
            }
        }
    }

    .c-slider {
        @media #{md('xs')} {
            --slide-width: 50%;
        }

        @media #{md('lg')} {
            --slide-width: 33.33%;
        }

        .c-slider__slide {
            padding: 0 var(--grid-gutter);
        }
    }

    .flickity-viewport {
        // overflow: hidden;
    }

    .flickity-slider {
        display: flex;
        align-items: flex-start;
    }

    .c-slider__side {
        justify-content: flex-end;
    }

    .c-slider__btn,
    .c-slide__count {
        --nav-bg: #{$color-light};
    }

    figcaption {
        text-align: center;
        margin-top: 1rem;
        font-size: fs("small");
    }
}
</style>
