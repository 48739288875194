<template>
    <div class="l-tab-list" v-if="tabs">
        <div>
            <btn
                v-for="(tab, index) in tabs"
                :key="`tab-${index}`"
                ref="tabBtn"
                tag="router-link"
                :class="{ '-active-tab': tab.uri.split('/').pop() == activeTab }"
                :url="`/${tab.uri || ''}`"
                :label="tab.title"
                bg-color="beige"
                @click.native="toggleTab(tab)"
            />
        </div>
    </div>
</template>

<script>
import Btn from "components/Btn.vue";
export default {
    name: "TabList",
    components: {
        Btn
    },
    props: {
        tabs: {
            type: Array | Object,
            required: true
        }
    },
    data: () => ({
        activeTab: null
    }),
    mounted() {},
    methods: {
        async centerActiveTab() {
            const activeBtn = (await this.$refs)
                ? [...this.$refs.tabBtn].filter(btn => [...btn.$el.classList].includes("-active-tab"))[0]
                : document.querySelector(".c-btn.-active-tab");

            if (activeBtn) {
                let container = this.$el.children[0];
                container.scrollTo(activeBtn.$el.getBoundingClientRect().left, 0);
            }
        },
        getActiveTab() {
            if (!this.$route.params) return;
            this.activeTab = this.$route.params.slug;
            this.centerActiveTab();
        },
        toggleTab(tab) {
            this.$emit("toggle-tab", tab);
        }
    },
    watch: {
        $route: {
            handler: "getActiveTab",
            immediate: true,
            deep: true
        }
    }
};
</script>

<style lang="scss">
.l-tab-list {
    .c-btn {
        --btn-bg-color: 237, 237, 221;
        position: relative;
        padding: 1.5rem;
        white-space: pre;

        .o-bg-shape {
            border-bottom-left-radius: 0;
        }

        &:last-of-type {
            .o-bg-shape {
                // border-bottom-right-radius: var(--border-radius);
            }
        }

        .c-btn__bg {
            transition: none;
        }

        &:before {
            @include pseudo-el($width: var(--grid-gutter-half), $height: var(--grid-gutter-half));
            position: absolute;
            left: calc(var(--grid-gutter-half) * -1);
            bottom: 0;
            background: radial-gradient(
                circle at top left,
                rgba(var(--btn-bg-color), 0) var(--grid-gutter-half),
                rgba(var(--btn-bg-color), 1) calc(var(--grid-gutter-half) + 0.1rem)
            );
            z-index: 1;
        }

        &:after {
            @include pseudo-el($width: var(--grid-gutter-half), $height: var(--grid-gutter-half));
            position: absolute;
            right: calc(var(--grid-gutter-half) * -1);
            bottom: 0;
            background: radial-gradient(
                circle at top right,
                rgba(var(--btn-bg-color), 0) var(--grid-gutter-half),
                rgba(var(--btn-bg-color), 1) calc(var(--grid-gutter-half) + 0.1rem)
            );
            z-index: 1;
        }

        &:hover {
            --btn-bg-color: 219, 219, 188;
            z-index: 3;
        }

        &.-active-tab {
            --btn-bg-color: 255, 255, 255;
            z-index: 4;
            .o-bg-shape {
                --bg-color: white;
                --hover-color: white;
            }
        }
    }

    .o-bg-shape {
        --border-radius: #{$border-radius-md};
        border-bottom-right-radius: 0;
    }

    > div {
        display: flex;
        flex-direction: row;
        overflow: auto;
        overflow-y: hidden;
        padding-right: 3rem;

        @media #{md('sm')} {
            max-width: calc(100% - 15rem); // 15rem width of menu
        }

        .is-windows.is-edge & {
            &::-webkit-scrollbar {
                background-color: var(--body-bg-color);

                &:horizontal {
                    height: 1rem;
                }

                &-thumb {
                    background-color: var(--body-alt-color);
                    opacity: 0.6;
                    border-radius: 2rem;
                }
            }
        }
    }
}
</style>
