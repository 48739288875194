<template>
    <grid-content class="l-builder" :class="{ '-has-anchors': displayAnchors && anchors.length }">
        <template #left>
            <anchors-list
                v-if="displayAnchors && anchors.length"
                :anchors="anchors"
                class="l-builder__anchors"
                ref="anchorsList"
            />
        </template>
        <template #right>
            <div class="l-builder__sections">
                <slot name="beforeSections" />
                <section v-for="(section, i) in sections" class="-s" :key="`section-${i}`" :id="sectionId(section)">
                    <div class="-s__title" v-if="section.label && section.displayLabel">
                        <span class="t-t4">{{ section.label }}</span>
                    </div>

                    <template v-if="section.children.length > 0">
                        <div
                            v-for="(block, i) in section.children"
                            :key="`block-${i}`"
                            class="-b"
                            :class="block.typeHandle | snake"
                        >
                            <component :is="block.typeHandle" :data="block" />
                        </div>
                    </template>
                </section>
            </div>
        </template>
    </grid-content>
</template>

<script>
import GridContent from "layout/GridContent.vue";
import AnchorsList from "components/AnchorsList";

import BlockAccordions from "blocks/BlockAccordions";
import BlockContent from "blocks/BlockContent";
import BlockContentCarousel from "blocks/BlockContentCarousel";
import BlockContentForm from "blocks/BlockContentForm";
import BlockEmbed from "blocks/BlockEmbed";
import BlockEvents from "blocks/BlockEvents";
import BlockFeaturedEntries from "blocks/BlockFeaturedEntries.vue";
import BlockFeaturedEntry from "blocks/BlockFeaturedEntry.vue";
import BlockGallery from "blocks/BlockGallery";
import BlockHeading from "blocks/BlockHeading";
import BlockHighlight from "blocks/BlockHighlight.vue";
import BlockHighlightWithImage from "blocks/BlockHighlightWithImage.vue";
import BlockImage from "blocks/BlockImage";
import BlockPartners from "blocks/BlockPartners";
import BlockPersons from "blocks/BlockPersons.vue";
import BlockResources from "blocks/BlockResources.vue";
import BlockSpacer from "blocks/BlockSpacer";
import BlockStats from "blocks/BlockStats";
import BlockTabs from "blocks/BlockTabs.vue";
import BlockTextImage from "blocks/BlockTextImage.vue";
import BlockVideo from "blocks/BlockVideo";
import BlockVideos from "blocks/BlockVideos";

import { toKebab } from "src/utils";

export default {
    name: "AppBuilder",
    props: {
        sections: {
            type: Array,
            default: () => []
        },
        displayAnchors: {
            type: Boolean,
            default: false
        }
    },
    components: {
        GridContent,
        AnchorsList,

        BlockAccordions,
        BlockContent,
        BlockContentCarousel,
        BlockContentForm,
        BlockEmbed,
        BlockEvents,
        BlockFeaturedEntries,
        BlockFeaturedEntry,
        BlockGallery,
        BlockHeading,
        BlockHighlight,
        BlockHighlightWithImage,
        BlockImage,
        BlockPartners,
        BlockPersons,
        BlockResources,
        BlockSpacer,
        BlockStats,
        BlockTabs,
        BlockTextImage,
        BlockVideo,
        BlockVideos
    },
    mounted() {
        setTimeout(() => {
            if (this.anchors && this.displayAnchors) this.getAnchorsWidth();
        }, 500);

        window.addEventListener("resizeEnd", this.getAnchorsWidth);
    },
    computed: {
        anchors() {
            if (this.displayAnchors)
                return this.sections
                    .filter(section => section.label)
                    .map(section => {
                        return {
                            title: section.label,
                            id: "section-" + toKebab(section.label)
                        };
                    });

            return null;
        }
    },
    methods: {
        sectionId(section) {
            return `section-${toKebab(section.label) || section.id}`;
        },
        getAnchorsWidth() {
            const anchorsList = this.$el.querySelector(".l-builder__anchors");

            if (anchorsList) this.$el.style.setProperty("--anchors-width", anchorsList.offsetWidth + "px");
        }
    },
    destroyed() {
        window.removeEventListener("resizeEnd", this.getAnchorsWidth);
    },
    watch: {
        anchors(value) {
            if (value && this.displayAnchors) this.getAnchorsWidth();
        }
    }
};
</script>

<style lang="scss">
.l-builder {
    &.-has-anchors {
        &.l-grid-content {
            @media #{md("sm", "max")} {
                grid-template-columns: 100%;
            }
            @media #{md("sm")} {
                grid-template-columns: 4fr 8fr;
                //--grid-col-gap: var(--container-gutter);
                //--grid-col-left-min-width: calc(var(--anchors-width) + var(--grid-col-gap));
                //--grid-col-left: min(var(--grid-col-left-min-width), 33%);
                //--grid-col-right: min(calc(100% - var(--grid-col-left)), calc(66% - var(--grid-col-gap)));
            }

            @media #{md("lg")} {
                grid-template-columns: 3fr 9fr;
            }

            .l-grid-content__col.-left {
                min-height: 4rem; // ~ mobile anchorNav height
            }
        }

        .-s:first-of-type {
            margin-top: calc(var(--grid-gutter) * -1);
            padding-top: var(--section-spacer);
        }
    }

    .-s {
        --block-spacer: var(--grid-gutter);
        --section-spacer: calc(var(--block-spacer) * 2);

        &:not(:first-of-type) {
            padding-top: var(--section-spacer);
        }

        &__title {
            background: $color-beige;
            padding: var(--grid-gutter-half);
            border-radius: $border-radius-sm;

            @media #{md("sm")} {
                border-radius: $border-radius-md;
            }
        }

        .-b {
            &:nth-child(n + 2) {
                margin-top: var(--block-spacer);
            }

            &:nth-last-child(n + 2) {
                @media #{md("xs", "max")} {
                    margin-bottom: var(--block-spacer);
                }
            }

            &.block-heading + .block-content {
                margin-top: 2.4rem;
            }
        }
    }
}
</style>
