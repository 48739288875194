import getAsset from "./assets";

/*
 * Craft GraphQL Snippets
 */

/**********************************
 *
 *
 *  DEFAULTS
 *
 *
 **********************************/

// Default data
const defaultData = `
id
uri
`;

// Default entry
const entry = `
id
section: sectionHandle
type: typeHandle
title
slug
uri
`;

// Default category
const category = `
id
slug
title
`;

/**********************************
 *
 *
 *  ATOMS
 *
 *
 **********************************/

// SEO
const seo = `
seo {
    title
    description
}
`;

// Map (SuperTable)
const map = `
map {
    ...on map_BlockType {
        lng
        lat
    }
}
`;

const link = `
link: externalLink
`;

const hero = `
heading
description
colour
${getAsset("image", "header.hero")}
`;

const advancedHero = `
headerModel
assetShape
shape
`;

// Simple button
const button = `
label
colour
style
externalLink
target {
    uri
    title
}
`;

// Buttons (Matrix)
const buttons = `
buttons {
    ...on buttons_button_BlockType {
        ${button}
    }
  }
`;

// Numbers (Matrix)
const numbers = `
numbers {
    ...on numbers_number_BlockType {
        line1
        line2
    }
  }
`;

/**********************************
 *
 *
 *  ASSET
 *
 *
 **********************************/

const document = `
document {
    url
    title
}
`;

const logo = `
logo {
    url
    title
}
`;

/**********************************
 *
 *
 *  RELATIONS
 *
 *
 **********************************/

const campaignTypes = `
campaignTypes: relationCategoriesCampaignTypes {
   ${category}
}
`;

const campaignType = `
campaignType: relationCategoryCampaignTypes {
   ${category}
}
`;

const targetAudience = `
targetAudience: relationCategoryTargetAudiences {
   ${category}
}
`;

const postType = `
category: relationCategoryPostTypes {
   ${category}
}
`;

const pressType = `
category: relationCategoryPressTypes {
   ${category}
}
`;

const subjects = `
subjects: relationCategoriesSubjects {
   ${category}
}
`;

const relatedEntry = `
relatedEntry: relationEntryRelated {
    ${entry}
}
`;

const partners = `
partners: relationEntriesPartner {
   ${defaultData}
   title
   ...on partner_default_Entry {
       ${logo}
       website: externalLink
   }
}
`;

const video = `
video: relationEntryVideo {
   ${defaultData}
   title
   ...on video_default_Entry {
       ${getAsset("image", "card.16:9")}
       description
       link: externalLink
   }
}
`;

/**********************************
 *
 *
 *  BUILDER
 *
 *
 **********************************/

// Accordions
const blockAccordions = `
...on layout_BlockAccordions_BlockType {
    typeHandle
    openFirst: trueOrFalse1
    accordions: children {
        ...on layout_accordion_BlockType {
            heading
            content: body
        }
    }
}
`;

// Content
const blockContent = `
...on layout_BlockContent_BlockType {
    typeHandle
    content: body
    forceOneColumn: trueOrFalse1
}
`;

// Embed
const blockEmbed = `
...on layout_BlockEmbed_BlockType {
    typeHandle
    url: externalLink
}
`;

// Events
const blockEvents = `
...on layout_BlockEvents_BlockType {
    typeHandle
    title: heading
    events: children {
        ...on layout_event_BlockType {
            participants: label
            date: beginDate
            title: heading
            description
            link: externalLink
            ${getAsset("image", "card.16:9")}
        }
    }
}
`;

// FeaturedEntries
const blockFeaturedEntries = `
...on layout_BlockFeaturedEntries_BlockType {
    typeHandle
    title: label
    entries: relationEntriesRelated {
        ${entry}
    }
}
`;

// FeaturedEntry
const blockFeaturedEntry = `
...on layout_BlockFeaturedEntry_BlockType {
    typeHandle
    assetShape
    entry: relationEntryRelated {
        ${entry}
    }
}
`;

// Gallery
const blockGallery = `
...on layout_BlockGallery_BlockType {
    typeHandle
    displayCaption: trueOrFalse1
    ${getAsset("images")}
}
`;

// Heading
const blockHeading = `
...on layout_BlockHeading_BlockType {
    typeHandle
    heading
}
`;

// Highlight
const blockHighlight = `
...on layout_BlockHighlight_BlockType {
    typeHandle
    text: body2
}
`;

// Highlight with image
const blockHighlightWithImage = `
...on layout_BlockHighlightWithImage_BlockType {
    typeHandle
    heading: body2
    description
    ${buttons}
    ${getAsset("image")}
}
`;

const blockContentCarousel = `
...on layout_BlockContentCarousel_BlockType {
    typeHandle
    slides: children {
        ...on layout_ContentNode_BlockType {
            ${getAsset("image")}
            heading
            description
        }
    }
}
`;

const blockContentForm = `
...on layout_BlockContentForm_BlockType {
    typeHandle
    title: heading
    description
    ${getAsset("image")}
    formUrl: externalLink
}
`;

// Image
const blockImage = `
...on layout_BlockImage_BlockType {
    typeHandle
    displayCaption: trueOrFalse1
    ${getAsset("image")}
}
`;

// Partners
const blockPartners = `
...on layout_BlockPartners_BlockType {
    typeHandle
    title: heading
    ${partners}
}
`;

// Persons
const blockPersons = `
...on layout_BlockPersons_BlockType {
    typeHandle
    title: heading
    persons: children {
        ...on layout_person_BlockType {
            name: label
            role
            email
            phone
            description
            ${getAsset("image", "misc.person")}
        }
    }
}
`;

// Resources
const blockResources = `
...on layout_BlockResources_BlockType {
    typeHandle
    title: heading
    text: body
    documentsOnLeft: trueOrFalse1
    files: children {
        ...on layout_resource_BlockType {
            title: label
            ${document}
        }
    }
}
`;

// Spacer
const blockSpacer = `
...on layout_BlockSpacer_BlockType {
    typeHandle
}
`;

// Stats
const blockStats = `
...on layout_BlockStats_BlockType {
    typeHandle
    stats: ${numbers}
}
`;

// Tabs
const blockTabs = `
...on layout_BlockTabs_BlockType {
    typeHandle
    tabs: children {
        ...on layout_tab_BlockType {
            title: heading
            body
        }
    }
}
`;

// TextImage
const blockTextImage = `
...on layout_BlockTextImage_BlockType {
    typeHandle
    ${getAsset("image", "card.1:1")}
    imgRight: trueOrFalse1
    assetShape
    text: body2
}
`;

// Video
const blockVideo = `
...on layout_BlockVideo_BlockType {
    typeHandle
    ${video}
}
`;

// Videos
const blockVideos = `
...on layout_BlockVideos_BlockType {
    typeHandle
    title: heading
    videos: children {
        ...on layout_video_BlockType {
            participants: label
            ${video}
        }
    }
}
`;

// Section
const blockSection = `
...on layout_BlockSection_BlockType {
    typeHandle
    id
    label
    displayLabel: trueOrFalse1
    children {
        ${blockAccordions}
        ${blockContent}
        ${blockEmbed}
        ${blockEvents}
        ${blockFeaturedEntries}
        ${blockFeaturedEntry}
        ${blockGallery}
        ${blockHeading}
        ${blockHighlight}
        ${blockHighlightWithImage}
        ${blockContentCarousel}
        ${blockContentForm}
        ${blockImage}
        ${blockPartners}
        ${blockPersons}
        ${blockResources}
        ${blockSpacer}
        ${blockStats}
        ${blockTabs}
        ${blockTextImage}
        ${blockVideo}
        ${blockVideos}
    }
}
`;

// Layout
const layout = `
hasAnchors: trueOrFalse1
sections: layout {
    ${blockSection}
}
`;

/**********************************
 *
 *
 *  CHANNELS AND STRUCTURES
 *
 *
 **********************************/

const page = `
${entry}
...on page_default_Entry {
    ${getAsset("image", "header.hero")}
    imageAlt: ${getAsset("image", "card.16:9")}
    assetShape
    shape
    colour
    description
    ${layout}
    ${seo}
    children {
        ${entry}
    }
    removePaddingBottom: trueOrFalse2
    useBackgroundHeaderModel: trueOrFalse3
    parent {
        title

        ...on page_default_Entry {
            description
            ${getAsset("image", "header.hero")}
            backgroundImage: ${getAsset("image")}
            removePaddingBottom: trueOrFalse2
            useBackgroundHeaderModel: trueOrFalse3
        }
        children {
            ${entry}
        }
    }
}
`;

const project = `
${entry}
postDate
...on project_internal_Entry {
    state
    ${getAsset("image", "card.1:1")}
    imageAlt: ${getAsset("image", "card.16:9")}
    hero: ${getAsset("image", "header.hero")}
    colour
    description
    representative
    ${layout}
    ${subjects}
    ${seo}
}
...on project_external_Entry {
    state
    ${link}
    ${getAsset("image", "card.1:1")}
    colour
    description
    ${subjects}
}
 `;

const campaign = `
${entry}
postDate
...on campaign_default_Entry {
    ${getAsset("image", "card.1:1")}
    imageAlt: ${getAsset("image", "card.16:9")}
    hero: ${getAsset("image", "header.hero")}
    colour
    ${logo}
    edition
    beginDate @formatDateTime
    endDate @formatDateTime
    description
    ${layout}
    ${campaignType}
    ${subjects}
    ${seo}
}
 `;

const vision = `
${entry}
postDate
...on vision_default_Entry {
    ${getAsset("image", "card.16:9")}
    hero: ${getAsset("image", "header.hero")}
    description
    colour
    ${layout}
    ${subjects}
    ${seo}
}
 `;

const issue = `
${entry}
postDate
...on issue_internal_Entry {
    ${getAsset("image", "card.16:9")}
    hero: ${getAsset("image", "header.hero")}
    description
    colour
    ${layout}
    ${subjects}
    ${seo}
}
...on issue_external_Entry {
    ${getAsset("image", "card.16:9")}
    description
    ${link}
    ${subjects}
}
 `;

const post = `
${entry}
datePost: postDate @formatDateTime
postDate
...on post_internal_Entry {
    ${getAsset("image", "card.16:9")}
    hero: ${getAsset("image", "header.hero")}
    description
    colour
    ${layout}
    ${postType}
    ${relatedEntry}
    ${targetAudience}
    ${subjects}
    ${seo}
}
...on post_external_Entry {
    ${getAsset("image", "card.16:9")}
    ${link}
    ${postType}
    ${relatedEntry}
    ${targetAudience}
    ${subjects}
}
...on post_document_Entry {
    ${getAsset("image", "card.16:9")}
    ${document}
    ${postType}
    ${relatedEntry}
    ${targetAudience}
    ${subjects}
}
 `;

const pressArticle = `
${entry}
datePost: postDate @formatDateTime
postDate
...on pressArticle_internal_Entry {
    description
    ${getAsset("image", "card.16:9")}
    imageSquare: ${getAsset("image", "card.1:1")}
    hero: ${getAsset("image", "header.hero")}
    colour
    ${layout}
    ${pressType}
    ${relatedEntry}
    ${subjects}
    ${seo}
}
...on pressArticle_external_Entry {
    description
    ${getAsset("image", "card.16:9")}
    imageSquare: ${getAsset("image", "card.1:1")}
    ${link}
    ${pressType}
    ${relatedEntry}
    ${subjects}
}
...on pressArticle_document_Entry {
    description
    ${getAsset("image", "card.16:9")}
    imageSquare: ${getAsset("image", "card.1:1")}
    ${document}
    ${pressType}
    ${relatedEntry}
    ${subjects}
}
 `;

// Exports
export default {
    // Defaults
    defaultData,
    entry,
    category,

    // Atoms
    seo,
    map,
    hero,
    advancedHero,
    buttons,
    numbers,

    // Relations
    campaignTypes,

    // Builder
    layout,

    // Entries
    page,
    project,
    campaign,
    vision,
    issue,
    post,
    pressArticle
};
