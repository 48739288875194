<template>
    <component
        :is="tag"
        class="c-highlight"
        :style="
            `
            --highlight-bg: var(--color-${highlightBg});
        `
        "
    >
        <anim-text
            :text="textFormatted"
            :options="options"
            :visible="true"
            :lines="true"
            ref="animText"
            :class="className"
            @paused="revealHighlight"
        />
    </component>
</template>

<script>
import AnimText from "objects/AnimText.vue";
import { COLORS } from "src/constants";

export default {
    name: "HighlightText",
    components: {
        AnimText
    },
    props: {
        text: String | Number,
        options: Object,
        highlightBg: {
            type: String,
            default: "blue-light",
            validator: color => COLORS.indexOf(color) !== -1
        },
        className: {
            type: String,
            default: ""
        },
        tag: {
            type: String,
            default: "div"
        }
    },
    data: () => ({
        highlightWords: []
    }),
    computed: {
        textFormatted() {
            // Remove all the strong tags
            if (this.text) return this.text.toString().replace(/(<strong>|<\/strong>)/g, "");
        }
    },
    mounted() {
        this.setHighlightWords();
    },
    methods: {
        revealHighlight() {
            this.$refs.animText.tl.play("highlights");
        },
        setHighlightWords() {
            let words = document.querySelectorAll(".o-at__w");

            const highlightWords = this.getHighlightWords(this.text);

            words.forEach(word => {
                let text = word.innerText;
                if (highlightWords.includes(text)) {
                    let span = document.createElement("span");
                    span.classList.add("-highlight");
                    span.textContent = text;
                    word.innerText = "";
                    word.append(span);
                }
            });
        },
        getHighlightWords(text) {
            if (typeof text != "string") return [];

            let parser = new DOMParser(),
                textParsed = parser.parseFromString(text, "text/html"),
                // TODO: Check if the cms returns bold elements with 'strong' and/or 'b'
                boldElements = textParsed.querySelectorAll("strong"),
                boldWords = [...boldElements].map(el => el.innerText);

            boldWords.forEach(word => {
                const wordArray = word.split(" ");
                this.highlightWords.push(...wordArray);
            });

            let highlightWords = this.highlightWords;
            return highlightWords;
        }
    }
};
</script>

<style lang="scss">
.c-highlight {
    .-highlight {
        line-height: 1.15;

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: -0.1em;
            right: -0.1em;
            z-index: -1;
            height: var(--highlight-height);

            border-radius: $border-radius-sm;
            background-color: var(--highlight-bg);
            transform: scaleY(0.875); // Avoid line-height overlap while using full height (100%) for animation
        }
    }
}
</style>
