<template>
    <section class="l-section | grid">
        <div class="l-section__content">
            <slot />
        </div>
    </section>
</template>

<script>
export default {
    name: "AppSection"
};
</script>

<style lang="scss">
.l-section {
    --section-padding: 4rem;

    padding-top: var(--section-padding-top, var(--section-padding));
    padding-bottom: var(--section-padding-bottom, var(--section-padding));
    background-color: var(--bg-color, transparent);

    @media #{md("sm")} {
        --section-padding: 6rem;
    }
}
</style>
