<template>
    <div v-if="data && currentPage" class="b-highlight-with-image">
        <div class="b-highlight-with-image__content">
            <div class="b-highlight-with-image__text | t-content">
                <highlight-text
                    tag="h2"
                    :text="data.heading"
                    :highlightBg="currentPage.colour"
                    class="t-t1"
                    is-narrow
                />
                <div class="t-cms -lead">
                    <p v-html="data.description" />
                </div>
                <div v-if="buttons && buttons.length" class="-actions | c-btn__group">
                    <btn
                        v-for="(button, i) in buttons"
                        :key="`home-content-btn-${i}`"
                        :tag="button.tag"
                        :url="button.url"
                        :label="button.label"
                        :bg-color="button.colour"
                        :is-shaped="button.isShape"
                        :iconAfter="button.iconAfter"
                    />
                </div>
            </div>

            <div class="b-highlight-with-image__medias">
                <asset :asset="data.image" :shape="data.assetShape" @loaded="$emit('imageLoaded')" />
            </div>
        </div>
    </div>
</template>

<script>
import Asset from "objects/Asset";
import Btn from "components/Btn";
import HighlightText from "../components/HighlightText.vue";
import { parseButtons } from "src/store/parse";
import { mapState } from "vuex";

export default {
    name: "BlockHighlightWithImage",
    components: { Asset, Btn, HighlightText },
    props: {
        data: String | Number,
        tag: String,
        options: Object,
        highlightBg: String
    },
    computed: {
        ...mapState({
            currentPage: state => state.global.currentPage
        }),
        buttons() {
            if (!this.data.buttons) return null;
            const _buttons = JSON.parse(JSON.stringify(this.data.buttons));
            return parseButtons(_buttons);
        }
    }
};
</script>

<style lang="scss">
.b-highlight-with-image {
    &__content {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: var(--grid-gutter);
        align-items: center;
        margin-top: var(--container-gutter);
        padding-bottom: var(--container-gutter);
        overflow: visible;

        @media #{md("xs")} {
            margin-top: calc(var(--container-gutter) + var(--grid-gutter));
        }

        @media #{md("sm")} {
            margin-top: var(--container-gutter);
            padding-right: calc(var(--nav-trigger-width) / 2);
        }

        .b-highlight-with-image__text {
            --content-gap: 4rem;
            padding-top: var(--grid-gutter);
            grid-column: 1 / 13;
            grid-row: 2 / 2;

            @media #{md("sm")} {
                grid-column: 1 / 13;
            }

            @media #{md("md")} {
                grid-column: 1 / 8;
                grid-row: 1 / 1;
            }

            @media #{md("lg")} {
                grid-column: 1 / 8;
            }

            .t-t1 {
                @include responsive-type($min-font: 35, $max-font: 60, $min-width: 480, $max-width: 1440);
                letter-spacing: -0.025em;

                p {
                    line-height: inherit;
                }
            }

            .t-cms {
                max-width: 45rem;
            }
        }

        .b-highlight-with-image__medias {
            grid-column: 2 / 12;
            grid-row: 1 / 1;

            @media #{md("xs")} {
                grid-column: 1 / 9;
            }

            @media #{md("md")} {
                grid-column: 8 / 13;
                grid-row: 1 / 1;
            }

            @media #{md("lg")} {
                grid-column: 8 / 13;
            }
        }
    }
}
</style>
