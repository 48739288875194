<template>
    <div v-if="data && data.formUrl" class="b-content-form">
        <div class="b-content-form__container | l-grid" :style="styles">
            <div class="grid__row">
                <div class="b-content-form__medias | grid__col grid__col--12 grid__col--6@md grid__col--5@lg">
                    <asset v-if="data.image" class="b-content-form__medias-asset" :asset="data.image" @loaded="init" />
                    <shape v-if="data.image" class="b-content-form__medias-shape" shape="square" :outline="false" />
                </div>
                <div
                    class="b-content-form__content | t-content | grid__col grid__col--12 grid__col--6@md --offset-1@md"
                >
                    <h2 v-if="data.title" class="b-content-form__title | t-t3">{{ data.title }}</h2>
                    <div v-if="data.description" class="b-content-form__description">
                        <p class="-lead" v-html="data.description" />
                    </div>
                    <div class="b-content-form__form">
                        <div>
                            <embed :src="data.formUrl" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Asset from "objects/Asset";
import Shape from "objects/Shape";
export default {
    name: "BlockContentForm",
    components: { Asset, Shape },
    props: {
        data: String | Number
    },
    data() {
        return {
            mediasWidth: 0
        };
    },
    computed: {
        styles() {
            let styles = "";

            styles += `
                --b-content-form-medias-width: ${this.mediasWidth}px;
            `;

            return styles;
        }
    },
    methods: {
        init() {
            if (this.$el.querySelector(".b-content-form__medias-asset")) {
                this.mediasWidth = this.$el.querySelector(".b-content-form__medias-asset").offsetWidth;
            }
        }
    },
    created() {
        // Add Window events
        window.addEventListener("resizeEnd", this.init);
    },
    destroyed() {
        // Remove Window events
        window.removeEventListener("resizeEnd", this.init);
    }
};
</script>

<style lang="scss">
.b-content-form {
    --b-content-form-radius: #{$border-radius-md};

    @media #{md("sm")} {
        --b-content-form-radius: #{$border-radius-lg};
    }

    margin-top: var(--container-gutter);
    padding: var(--container-gutter);
    background-color: var(--color-beige);
    border-radius: var(--b-content-form-radius);

    &__medias {
        position: relative;

        .o-shape {
            display: block;
            width: 100%;
            margin-top: var(--grid-gutter);
            color: var(--body-alt-color);
            --svg-width: var(--b-content-form-medias-width);
        }
    }

    &__form {
        margin-top: var(--grid-gutter-half);
        embed {
            width: 100%;
            min-height: 750px;
        }
    }

    // Bleed override
    --offset: calc(var(--section-padding) + var(--grid-gutter-half));
    margin-left: calc(var(--offset) * -1);
    margin-right: calc(var(--offset) * -1);

    &__container {
        padding-left: calc(var(--section-padding) - var(--grid-gutter-half));
        padding-right: calc(var(--section-padding) - var(--grid-gutter-half));
    }
}
</style>
