// For router' linkResolver
export const SECTION_HANDLES = Object.freeze({
    HOME: "home",
    PROJECTS: "projects",
    PROJECT: "project",
    CAMPAIGNS: "campaigns",
    CAMPAIGN: "campaign",
    VISIONS: "visions",
    VISION: "visions",
    ISSUES: "issues",
    ISSUE: "issue",
    POSTS: "posts",
    POST: "post",
    PRESS: "press",
    PRESS_ARTICLE: "pressArticle",
    PAGE: "page",
    CONTACT: "contact"
});

// For Vuex stores
export const STORE_MODULES = Object.freeze([
    "global",
    "pages",
    "project",
    "campaign",
    "vision",
    "issue",
    "post",
    "pressArticle",
    "loader"
]);

// Shapes
export const SHAPES = Object.freeze([
    "losange",
    "square",
    "circle",
    "pentagon-up",
    "pentagon-down",
    "hexagon",
    "triangle-up",
    "triangle-down"
]);

export const COLORS = Object.freeze([
    "transparent",
    "beige",
    "blue",
    "blue-light",
    "dark",
    "light",
    "orange",
    "orange-light",
    "red",
    "red-light",
    "yellow",
    "yellow-light",
    "purple",
    "purple-light",
    "facebook",
    "instagram",
    "twitter",
    "youtube",
    "linkedin"
]);
